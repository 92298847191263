import { BrowserRouter, Route, Routes } from "react-router-dom";
import Appointment from "./Appointment";
import Dashboard from "./Dashboard";
import "./App.css";

function App() {
return (
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<Appointment />} />
      <Route path="/dream/admin" element={<Dashboard />} />
    </Routes>
  </BrowserRouter>
)
}

export default App;
