// import "./App.css";
import toast, { Toaster } from "react-hot-toast";
import { useFormik } from "formik";
import * as Yup from "yup";
import { createClient } from "@supabase/supabase-js";
import {
  loadCaptchaEnginge,
  LoadCanvasTemplate,
  LoadCanvasTemplateNoReload,
  validateCaptcha,
} from "react-simple-captcha";
import { useEffect } from "react";
import logo from "./assets/logo.png";

const supabase = createClient(
  process.env.REACT_APP_SUPABASE_URL,
  process.env.REACT_APP_SUPABASE_ANON_KEY
);

const notify = (name) => toast(`Appointment Created`);

const dates = {
  "Dr. Abreham": ["Monday", "Wednesday", "Saturday"],
  "Dr. Ephrem": ["Tuesday", "Thursday", "Saturday"],
  "Dr. Mamo": ["Monday", "Wednesday", "Saturday"],
  "Dr. Samson": ["Monday", "Wednesday", "Friday"],
  "Dr. Tewodros": ["Tuesday", "Thursday", "Saturday"],
};

const times = {
  "Dr. Abreham": {
    "Monday": [
      "1:00 PM",
      "1:30 PM",
      "2:00 PM",
      "2:30 PM",
      "3:00 PM",
      "3:30 PM",
      "4:00 PM",
    ],
    "Wednesday": [
      "11:00 AM",
      "11:30 AM",
      "12:00 PM",
      "12:30 PM",
      "1:00 PM",
      "1:30 PM",
      "2:00 PM",
    ],
    "Saturday": [
      "9:00 AM",
      "9:30 AM",
      "10:00 AM",
      "10:30 AM",
      "11:00 AM",
      "11:30 AM",
      "12:00 PM",
      "12:30 PM",
      "1:00 PM",
    ],
  },
  "Dr. Ephrem": {
    "Tuesday": [
      "9:00 AM",
      "9:30 AM",
      "10:00 AM",
      "10:30 AM",
      "11:00 AM",
      "11:30 AM",
      "12:00 PM",
      "12:30 PM",
      "1:00 PM",
      "1:30 PM",
      "2:00 PM",
      "2:30 PM",
      "3:00 PM",
      "3:30 PM",
      "4:00 PM",
      "4:30 PM",
      "5:00 PM",
    ],
    "Thursday": [
      "9:00 AM",
      "9:30 AM",
      "10:00 AM",
      "10:30 AM",
      "11:00 AM",
      "11:30 AM",
      "12:00 PM",
      "12:30 PM",
      "1:00 PM",
      "1:30 PM",
      "2:00 PM",
      "2:30 PM",
      "3:00 PM",
      "3:30 PM",
      "4:00 PM",
      "4:30 PM",
      "5:00 PM",
    ],
    "Saturday": [
      "9:00 AM",
      "9:30 AM",
      "10:00 AM",
      "10:30 AM",
      "11:00 AM",
      "11:30 AM",
      "12:00 PM",
      "12:30 PM",
      "1:00 PM",
    ],
  },
  "Dr. Mamo": {
    "Monday": [
      "9:00 AM",
      "9:30 AM",
      "10:00 AM",
      "10:30 AM",
      "11:00 AM",
      "11:30 AM",
      "12:00 PM",
      "12:30 PM",
      "1:00 PM",
      "1:30 PM",
      "2:00 PM",
      "2:30 PM",
      "3:00 PM",
    ],
    "Wednesday": [
      "2:00 PM",
      "2:30 PM",
      "3:00 PM",
      "3:30 PM",
      "4:00 PM",
      "4:30 PM",
      "5:00 PM",
    ],
    "Saturday": [
      "9:00 AM",
      "9:30 AM",
      "10:00 AM",
      "10:30 AM",
      "11:00 AM",
      "11:30 AM",
      "12:00 PM",
      "12:30 PM",
      "1:00 PM",
    ],
  },
  "Dr. Samson": {
    "Monday": [
      "9:00 AM",
      "9:30 AM",
      "10:00 AM",
      "10:30 AM",
      "11:00 AM",
      "11:30 AM",
      "12:00 PM",
      "12:30 PM",
      "1:00 PM",
      "1:30 PM",
      "2:00 PM",
      "2:30 PM",
      "3:00 PM",
      "3:30 PM",
      "4:00 PM",
      "4:30 PM",
      "5:00 PM",
    ],
    "Wednesday": [
      "9:00 AM",
      "9:30 AM",
      "10:00 AM",
      "10:30 AM",
      "11:00 AM",
      "11:30 AM",
      "12:00 PM",
      "12:30 PM",
      "1:00 PM",
      "1:30 PM",
      "2:00 PM",
      "2:30 PM",
      "3:00 PM",
      "3:30 PM",
      "4:00 PM",
      "4:30 PM",
      "5:00 PM",
    ],
    "Friday": [
      "9:00 AM",
      "9:30 AM",
      "10:00 AM",
      "10:30 AM",
      "11:00 AM",
      "11:30 AM",
      "12:00 PM",
      "12:30 PM",
      "1:00 PM",
      "1:30 PM",
      "2:00 PM",
      "2:30 PM",
      "3:00 PM",
      "3:30 PM",
      "4:00 PM",
      "4:30 PM",
      "5:00 PM",
    ],
  },
  "Dr. Tewodros": {
    "Tuesday": [
      "4:00 PM",
      "4:30 PM",
      "5:00 PM",
      "5:30 PM",
      "6:00 PM",
      "6:30 PM",
      "7:00 PM",
    ],
    "Thursday": [
      "9:00 AM",
      "9:30 AM",
      "10:00 AM",
      "10:30 AM",
      "11:00 AM",
      "11:30 AM",
      "12:00 PM",
      "12:30 PM",
      "1:00 PM",
      "1:30 PM",
      "2:00 PM",
      "2:30 PM",
      "3:00 PM",
      "3:30 PM",
      "4:00 PM",
      "4:30 PM",
      "5:00 PM",
    ],
    "Saturday": [
      "9:00 AM",
      "9:30 AM",
      "10:00 AM",
      "10:30 AM",
      "11:00 AM",
      "11:30 AM",
      "12:00 PM",
      "12:30 PM",
      "1:00 PM",
    ],
  },
};

const doctors = [
  "Dr. Abreham",
  "Dr. Ephrem",
  "Dr. Mamo",
  "Dr. Samson",
  "Dr. Tewodros",
];


// Create a function to handle inserts
// const handleInserts = (payload) => {
//   console.log("Change received!", payload);
//   notify(payload.new.name);
// };

// // Listen to inserts
// supabase
//   .channel("countries")
//   .on(
//     "postgres_changes",
//     { event: "INSERT", schema: "public", table: "appointment" },
//     handleInserts
//   )
//   .subscribe();

function Appointment() {
  // const [countries, setCountries] = useState([]);

  useEffect(() => {
    loadCaptchaEnginge(6);
  }, []);

  // async function getCountries() {
  //   const { data } = await supabase.from("appointment").select();
  //   setCountries(data);
  // }

  async function createAppointment(data) {
    const { error } = await supabase.from("appointment").insert([data]);
    if (error) {
      console.log(error);
    }
  }

  const schema = Yup.object().shape({
    name: Yup.string()
      .min(2, "Too Short!")
      .max(30, "Too Long!")
      .required("Required"),
    age: Yup.number().required("Required"),
    gender: Yup.string()
      .min(2, "Too Short!")
      .max(30, "Too Long!")
      .required("Required"),
    drname: Yup.string().required("Required"),
    phone: Yup.string()
      .min(8, "Too Short!")
      .max(15, "Too Long!")
      .required("Required"),
    date: Yup.string().required("Required"),
    time: Yup.string().required("Required"),

    captcha: Yup.string().required("Required"),
  });

  const { handleSubmit, handleChange, resetForm, values, touched, errors } =
    useFormik({
      initialValues: {
        name: "",
        age: "",
        gender: "",
        drname: "",
        phone: "",
        date: "",
        time: "",
        captcha: "",
      },
      validationSchema: schema,
      onSubmit: (values) => {
        const { name, age, gender, drname, phone, date,time, captcha } = values;
        const data = {
          name,
          age,
          gender,
          drname,
          phone,
          date,
          time,
        };
        if (validateCaptcha(captcha) === true) {
          // console.log(data);
          createAppointment(data);
          notify(data.name);
          resetForm();
        } else {
          alert("Captcha Does Not Match");
        }
      },
    });

  return (
    <div className="flex items-center justify-center h-screen">
      <div className="lg:w-1/5 flex flex-col  m-4">
        <img src={logo} alt="logo" className="mb-3 mt-3" />
        <Toaster position="bottom-right" reverseOrder={false} />
        <label className="input input-bordered flex items-center gap-2 mb-3">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 16 16"
            fill="currentColor"
            className="w-4 h-4 opacity-70"
          >
            <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6ZM12.735 14c.618 0 1.093-.561.872-1.139a6.002 6.002 0 0 0-11.215 0c-.22.578.254 1.139.872 1.139h9.47Z" />
          </svg>
          <input
            type="text"
            className="grow"
            placeholder="Full Name"
            name="name"
            value={values.name}
            onChange={handleChange}
          />
        </label>
        {touched.name && errors.name ? (
          <div className="text-red-600">{errors.name}</div>
        ) : null}
        <label className="input input-bordered flex items-center gap-2 mb-3">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 16 16"
            fill="currentColor"
            className="w-4 h-4 opacity-70"
          >
            <path d="M2.5 3A1.5 1.5 0 0 0 1 4.5v.793c.026.009.051.02.076.032L7.674 8.51c.206.1.446.1.652 0l6.598-3.185A.755.755 0 0 1 15 5.293V4.5A1.5 1.5 0 0 0 13.5 3h-11Z" />
            <path d="M15 6.954 8.978 9.86a2.25 2.25 0 0 1-1.956 0L1 6.954V11.5A1.5 1.5 0 0 0 2.5 13h11a1.5 1.5 0 0 0 1.5-1.5V6.954Z" />
          </svg>
          <input
            type="text"
            className="grow"
            placeholder="Age"
            name="age"
            value={values.age}
            onChange={handleChange}
          />
        </label>
        {touched.age && errors.age ? (
          <div className="text-red-600">{errors.age}</div>
        ) : null}
        <label className="form-control mb-3">
          {/* <div className="label">
    <span className="label-text">Pick Doctor</span>
  </div> */}

          <select
            className="select select-bordered"
            name="gender"
            onChange={handleChange}
            value={values.gender}
          >
            <option disabled selected value={""}>
              Gender
            </option>
            <option>Male</option>
            <option>Female</option>
          </select>
        </label>
        {touched.gender && errors.gender ? (
          <div className="text-red-600">{errors.gender}</div>
        ) : null}
        <label className="input input-bordered flex items-center gap-2 mb-3">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 16 16"
            fill="currentColor"
            className="w-4 h-4 opacity-70"
          >
            <path
              fillRule="evenodd"
              d="M14 6a4 4 0 0 1-4.899 3.899l-1.955 1.955a.5.5 0 0 1-.353.146H5v1.5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-2.293a.5.5 0 0 1 .146-.353l3.955-3.955A4 4 0 1 1 14 6Zm-4-2a.75.75 0 0 0 0 1.5.5.5 0 0 1 .5.5.75.75 0 0 0 1.5 0 2 2 0 0 0-2-2Z"
              clipRule="evenodd"
            />
          </svg>
          <input
            type="text"
            className="grow"
            placeholder="Phone Number"
            name="phone"
            value={values.phone}
            onChange={handleChange}
          />
        </label>
        {touched.phone && errors.phone ? (
          <div className="text-red-600">{errors.phone}</div>
        ) : null}
        {/* <label className="form-control w-full max-w-xs"> */}
        <label className="form-control mb-3">
          {/* <div className="label">
    <span className="label-text">Pick Doctor</span>
  </div> */}

          <select
            className="select select-bordered"
            name="drname"
            onChange={handleChange}
            value={values.drname}
          >
            <option disabled selected value={""}>
              Pick Doctor
            </option>
            {doctors.map((doctor) => (
              <option>{doctor}</option>
            ))}
          </select>
        </label>
        {touched.drname && errors.drname ? (
          <div className="text-red-600">{errors.drname}</div>
        ) : null}
        {values.drname ? (
          <label className="form-control mb-3">
            {/* <div className="label">
    <span className="label-text">Pick Doctor</span>
  </div> */}

            <select
              className="select select-bordered"
              name="date"
              onChange={handleChange}
              value={values.date}
            >
              <option disabled selected value={""}>
                Pick Date
              </option>
              {dates[values.drname].map((date) => (
                <option>{date}</option>
              ))}
            </select>
          </label>
        ) : null}
        {touched.date && errors.date ? (
          <div className="text-red-600">{errors.date}</div>
        ) : null}
        {values.date ? (
          <label className="form-control mb-3">
            {/* <div className="label">
    <span className="label-text">Pick Doctor</span>
  </div> */}

            <select
              className="select select-bordered"
              name="time"
              onChange={handleChange}
              value={values.time}
            >
              <option disabled selected value={""}>
                Pick time
              </option>
              {times[values.drname][values.date].map((time) => (
                <option>{time}</option>
              ))}
            </select>
          </label>
        ) : null}
        {touched.time && errors.time ? (
          <div className="text-red-600">{errors.time}</div>
        ) : null}
        <LoadCanvasTemplateNoReload />
        <label className="input input-bordered flex items-center gap-2 mb-3">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 16 16"
            fill="currentColor"
            className="w-4 h-4 opacity-70"
          >
            <path
              fillRule="evenodd"
              d="M14 6a4 4 0 0 1-4.899 3.899l-1.955 1.955a.5.5 0 0 1-.353.146H5v1.5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-2.293a.5.5 0 0 1 .146-.353l3.955-3.955A4 4 0 1 1 14 6Zm-4-2a.75.75 0 0 0 0 1.5.5.5 0 0 1 .5.5.75.75 0 0 0 1.5 0 2 2 0 0 0-2-2Z"
              clipRule="evenodd"
            />
          </svg>
          <input
            type="text"
            className="grow"
            placeholder="Enter Captcha"
            name="captcha"
            value={values.captcha}
            onChange={handleChange}
          />
        </label>
        {touched.captcha && errors.captcha ? (
          <div className="text-red-600">{errors.captcha}</div>
        ) : null}

        <div className="flex justify-end mt-4">
          <button
            type="submit"
            onClick={handleSubmit}
            className="btn btn-neutral"
          >
            Send
          </button>
        </div>
      </div>
    </div>
  );
}

export default Appointment;
