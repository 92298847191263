import React, { forwardRef, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import toast, { Toaster } from "react-hot-toast";
import { useFormik } from "formik";
import * as Yup from "yup";
import { createClient } from "@supabase/supabase-js";
import addNotification from "react-push-notification";
import logo from "./assets/logo.png";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// import Calendar from "react-calendar";
// import "react-calendar/dist/Calendar.css";

const supabase = createClient(
  process.env.REACT_APP_SUPABASE_URL,
  process.env.REACT_APP_SUPABASE_ANON_KEY
);

const notify = (name) => toast(`Appointment Created`);

const dates = {
  "Dr. Abreham": ["Monday", "Wednesday", "Saturday"],
  "Dr. Ephrem": ["Tuesday", "Thursday", "Saturday"],
  "Dr. Mamo": ["Monday", "Wednesday", "Saturday"],
  "Dr. Samson": ["Monday", "Wednesday", "Friday"],
  "Dr. Tewodros": ["Tuesday", "Thursday", "Saturday"],
};

const times = {
  "Dr. Abreham": {
    "Monday": [
      "1:00 PM",
      "1:30 PM",
      "2:00 PM",
      "2:30 PM",
      "3:00 PM",
      "3:30 PM",
      "4:00 PM",
    ],
    "Wednesday": [
      "11:00 AM",
      "11:30 AM",
      "12:00 PM",
      "12:30 PM",
      "1:00 PM",
      "1:30 PM",
      "2:00 PM",
    ],
    "Saturday": [
      "9:00 AM",
      "9:30 AM",
      "10:00 AM",
      "10:30 AM",
      "11:00 AM",
      "11:30 AM",
      "12:00 PM",
      "12:30 PM",
      "1:00 PM",
    ],
  },
  "Dr. Ephrem": {
    "Tuesday": [
      "9:00 AM",
      "9:30 AM",
      "10:00 AM",
      "10:30 AM",
      "11:00 AM",
      "11:30 AM",
      "12:00 PM",
      "12:30 PM",
      "1:00 PM",
      "1:30 PM",
      "2:00 PM",
      "2:30 PM",
      "3:00 PM",
      "3:30 PM",
      "4:00 PM",
      "4:30 PM",
      "5:00 PM",
    ],
    "Thursday": [
      "9:00 AM",
      "9:30 AM",
      "10:00 AM",
      "10:30 AM",
      "11:00 AM",
      "11:30 AM",
      "12:00 PM",
      "12:30 PM",
      "1:00 PM",
      "1:30 PM",
      "2:00 PM",
      "2:30 PM",
      "3:00 PM",
      "3:30 PM",
      "4:00 PM",
      "4:30 PM",
      "5:00 PM",
    ],
    "Saturday": [
      "9:00 AM",
      "9:30 AM",
      "10:00 AM",
      "10:30 AM",
      "11:00 AM",
      "11:30 AM",
      "12:00 PM",
      "12:30 PM",
      "1:00 PM",
    ],
  },
  "Dr. Mamo": {
    "Monday": [
      "9:00 AM",
      "9:30 AM",
      "10:00 AM",
      "10:30 AM",
      "11:00 AM",
      "11:30 AM",
      "12:00 PM",
      "12:30 PM",
      "1:00 PM",
      "1:30 PM",
      "2:00 PM",
      "2:30 PM",
      "3:00 PM",
    ],
    "Wednesday": [
      "2:00 PM",
      "2:30 PM",
      "3:00 PM",
      "3:30 PM",
      "4:00 PM",
      "4:30 PM",
      "5:00 PM",
    ],
    "Saturday": [
      "9:00 AM",
      "9:30 AM",
      "10:00 AM",
      "10:30 AM",
      "11:00 AM",
      "11:30 AM",
      "12:00 PM",
      "12:30 PM",
      "1:00 PM",
    ],
  },
  "Dr. Samson": {
    "Monday": [
      "9:00 AM",
      "9:30 AM",
      "10:00 AM",
      "10:30 AM",
      "11:00 AM",
      "11:30 AM",
      "12:00 PM",
      "12:30 PM",
      "1:00 PM",
      "1:30 PM",
      "2:00 PM",
      "2:30 PM",
      "3:00 PM",
      "3:30 PM",
      "4:00 PM",
      "4:30 PM",
      "5:00 PM",
    ],
    "Wednesday": [
      "9:00 AM",
      "9:30 AM",
      "10:00 AM",
      "10:30 AM",
      "11:00 AM",
      "11:30 AM",
      "12:00 PM",
      "12:30 PM",
      "1:00 PM",
      "1:30 PM",
      "2:00 PM",
      "2:30 PM",
      "3:00 PM",
      "3:30 PM",
      "4:00 PM",
      "4:30 PM",
      "5:00 PM",
    ],
    "Friday": [
      "9:00 AM",
      "9:30 AM",
      "10:00 AM",
      "10:30 AM",
      "11:00 AM",
      "11:30 AM",
      "12:00 PM",
      "12:30 PM",
      "1:00 PM",
      "1:30 PM",
      "2:00 PM",
      "2:30 PM",
      "3:00 PM",
      "3:30 PM",
      "4:00 PM",
      "4:30 PM",
      "5:00 PM",
    ],
  },
  "Dr. Tewodros": {
    "Tuesday": [
      "4:00 PM",
      "4:30 PM",
      "5:00 PM",
      "5:30 PM",
      "6:00 PM",
      "6:30 PM",
      "7:00 PM",
    ],
    "Thursday": [
      "9:00 AM",
      "9:30 AM",
      "10:00 AM",
      "10:30 AM",
      "11:00 AM",
      "11:30 AM",
      "12:00 PM",
      "12:30 PM",
      "1:00 PM",
      "1:30 PM",
      "2:00 PM",
      "2:30 PM",
      "3:00 PM",
      "3:30 PM",
      "4:00 PM",
      "4:30 PM",
      "5:00 PM",
    ],
    "Saturday": [
      "9:00 AM",
      "9:30 AM",
      "10:00 AM",
      "10:30 AM",
      "11:00 AM",
      "11:30 AM",
      "12:00 PM",
      "12:30 PM",
      "1:00 PM",
    ],
  },
};

const doctors = [
  "Dr. Abreham",
  "Dr. Ephrem",
  "Dr. Mamo",
  "Dr. Samson",
  "Dr. Tewodros",
];

const Dashboard = () => {
  const [value, setValue] = useState([]);
  const [test, setTest] = useState([]);
  // const [date, setDate] = useState(new Date());
  const [checked, setChecked] = useState(true);
  const [startDate, setStartDate] = useState(new Date());

  useEffect(() => {
    getCountries();
  }, [test, checked, startDate]);

  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <button className="btn btn-accent" onClick={onClick} ref={ref}>
      {value}
    </button>
  ));

  async function createAppointment(data) {
    const { error } = await supabase.from("appointment").insert([data]);
    if (error) {
      console.log(error);
    }
  }

  const schema = Yup.object().shape({
    name: Yup.string()
      .min(2, "Too Short!")
      .max(30, "Too Long!")
      .required("Required"),
    age: Yup.number().required("Required"),
    gender: Yup.string()
      .min(2, "Too Short!")
      .max(30, "Too Long!")
      .required("Required"),
    drname: Yup.string().required("Required"),
    phone: Yup.string()
      .min(8, "Too Short!")
      .max(15, "Too Long!")
      .required("Required"),
    date: Yup.string().required("Required"),
    time: Yup.string().required("Required"),
  });

  const close = () => {
    document.getElementById("my_modal_1").showModal();
  };

  const { handleSubmit, handleChange, resetForm, values, touched, errors } =
    useFormik({
      initialValues: {
        name: "",
        age: "",
        gender: "",
        drname: "",
        phone: "",
        date: "",
        time:"",
      },
      validationSchema: schema,
      onSubmit: (values) => {
        const { name, age, gender, drname, phone, date,time } = values;
        const data = {
          name,
          age,
          gender,
          drname,
          phone,
          date,
          time,
          isappointed: true,
        };
        createAppointment(data);
        notify(data.name);
        resetForm();
        close();
      },
    });

  const buttonClick = () => {
    addNotification({
      title: "Notification",
      // subtitle: 'This is a subtitle',
      message: "New Appointment Added",
      theme: "darkblue",
      duration: 20000,
      native: true, // when using native, your OS will handle theming.
    });
  };

  const handleInserts = (payload) => {
    // console.log("Change received!", payload);
    buttonClick();
    setTest(payload.new);
    //   window.location.reload();
    //   notify(payload.new.name);
  };

  // Listen to inserts
  supabase
    .channel("countries")
    .on(
      "postgres_changes",
      { event: "*", schema: "public", table: "appointment" },
      handleInserts
    )
    .subscribe();

  async function getCountries() {
    if (checked === true) {
      const { data } = await supabase
        .from("appointment")
        .select()
        .order("id", { ascending: false });
      setValue(data);
    } else if (checked === false) {
      const { data } = await supabase
        .from("appointment")
        .select()
        .gte("created_at", startDate.toISOString().slice(0, 10))
        .lt("created_at", startDate.toISOString().slice(0, 10) + " 23:59:59")
        .order("id", { ascending: false });
      setValue(data);
    }
  }

  console.log(startDate.toISOString().slice(0, 10));

  async function appointed(id) {
    // console.log(id);
    const { data } = await supabase
      .from("appointment")
      .update({ isappointed: true })
      .eq("id", id);
    // if (data) {
    //   console.log(data);
    // }
  }

  const columns = [
    {
      name: "ID",
      selector: (row) => row.id,
      sortable: true,
      grow: 0,
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
      grow: 1,
    },
    {
      name: "Age",
      selector: (row) => row.age,
      sortable: true,
      grow: 1,
    },
    {
      name: "Gender",
      selector: (row) => row.gender,
      sortable: true,
    },
    {
      name: "Phonenumber",
      selector: (row) => row.phone,
      sortable: true,
    },
    {
      name: "DR. Name",
      selector: (row) => row.drname,
      sortable: true,
    },
    {
      name: "AP. Date",
      selector: (row) => row.date,
      sortable: true,
    },
    {
      name: "AP. Time",
      selector: (row) => row.time,
      sortable: true,
    },
    {
      name: "Created Date",
      selector: (row) => <div>{new Date(row.created_at).toDateString()}</div>,
      sortable: true,
    },
    {
      name: "Status",
      button: true,
      cell: (row) => (
        <button
          type="button"
          {...(row.isappointed ? { disabled: "disabled" } : {})}
          // disabled="disabled"
          className="btn mt-2 mb-2 py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          onClick={() => appointed(row.id)}
        >
          Done
        </button>
      ),
    },
  ];

  return (
    <div className="flex  justify-center min-h-screen bg-slate-100">
      <main className="flex-1">
        <div className="py-6 ">
          <div className="flex justify-center">
            <img src={logo} alt="logo" className="mb-3 w-1/4" />
          </div>
          <div className="mb-10 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex justify-between">
            <div>
              <h1 className="text-2xl font-semibold text-gray-900">
                Appointments
              </h1>
              <div className="flex mb-1">
                <input
                  type="checkbox"
                  defaultChecked={checked ? true : false}
                  onChange={() => setChecked(!checked)}
                  className="checkbox checkbox-accent mr-1"
                />

                <p>All Date</p>
              </div>

              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                disabled={checked ? true : false}
                customInput={<ExampleCustomInput />}
              />
            </div>

            <button
              className="btn mt-2 mb-2 py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              onClick={() => document.getElementById("my_modal_1").showModal()}
            >
              New
            </button>
          </div>
          <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
            {/* Replace with your content */}
            <div className="py-4 overflow-hidden bg-white shadow sm:rounded-lg">
              <DataTable
                // title="Movies"
                columns={columns}
                data={value}
                defaultSortFieldID={1}
                pagination
              />
            </div>
            {/* /End replace */}
          </div>
        </div>
      </main>
      <dialog id="my_modal_1" className="modal">
        <div className="modal-box bg-white">
          <form method="dialog">
            {/* if there is a button in form, it will close the modal */}
            <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">
              ✕
            </button>
          </form>
          <label className=" bg-white input input-bordered flex items-center gap-2 mb-3 mt-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 16 16"
              fill="currentColor"
              className="w-4 h-4 opacity-70"
            >
              <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6ZM12.735 14c.618 0 1.093-.561.872-1.139a6.002 6.002 0 0 0-11.215 0c-.22.578.254 1.139.872 1.139h9.47Z" />
            </svg>
            <input
              type="text"
              className="grow"
              placeholder="Full Name"
              name="name"
              value={values.name}
              onChange={handleChange}
            />
          </label>
          {touched.name && errors.name ? (
            <div className="text-red-600">{errors.name}</div>
          ) : null}
          <label className="bg-white input input-bordered flex items-center gap-2 mb-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 16 16"
              fill="currentColor"
              className="w-4 h-4 opacity-70"
            >
              <path d="M2.5 3A1.5 1.5 0 0 0 1 4.5v.793c.026.009.051.02.076.032L7.674 8.51c.206.1.446.1.652 0l6.598-3.185A.755.755 0 0 1 15 5.293V4.5A1.5 1.5 0 0 0 13.5 3h-11Z" />
              <path d="M15 6.954 8.978 9.86a2.25 2.25 0 0 1-1.956 0L1 6.954V11.5A1.5 1.5 0 0 0 2.5 13h11a1.5 1.5 0 0 0 1.5-1.5V6.954Z" />
            </svg>
            <input
              type="text"
              className="grow"
              placeholder="Age"
              name="age"
              value={values.age}
              onChange={handleChange}
            />
          </label>
          {touched.age && errors.age ? (
            <div className="text-red-600">{errors.age}</div>
          ) : null}
          <label className="form-control mb-3 bg-white ">
            {/* <div className="label">
    <span className="label-text">Pick Doctor</span>
  </div> */}

            <select
              className="select select-bordered bg-white "
              name="gender"
              onChange={handleChange}
              value={values.gender}
            >
              <option disabled selected value={""}>
                Gender
              </option>
              <option>Male</option>
              <option>Female</option>
            </select>
          </label>
          {touched.gender && errors.gender ? (
            <div className="text-red-600">{errors.gender}</div>
          ) : null}
          <label className="bg-white input input-bordered flex items-center gap-2 mb-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 16 16"
              fill="currentColor"
              className="w-4 h-4 opacity-70"
            >
              <path
                fillRule="evenodd"
                d="M14 6a4 4 0 0 1-4.899 3.899l-1.955 1.955a.5.5 0 0 1-.353.146H5v1.5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-2.293a.5.5 0 0 1 .146-.353l3.955-3.955A4 4 0 1 1 14 6Zm-4-2a.75.75 0 0 0 0 1.5.5.5 0 0 1 .5.5.75.75 0 0 0 1.5 0 2 2 0 0 0-2-2Z"
                clipRule="evenodd"
              />
            </svg>
            <input
              type="text"
              className="grow"
              placeholder="Phone Number"
              name="phone"
              value={values.phone}
              onChange={handleChange}
            />
          </label>
          {touched.phone && errors.phone ? (
            <div className="text-red-600">{errors.phone}</div>
          ) : null}
          {/* <label className="form-control w-full max-w-xs"> */}
          <label className="form-control mb-3 bg-white ">
            {/* <div className="label">
    <span className="label-text">Pick Doctor</span>
  </div> */}

            <select
              className="select select-bordered bg-white "
              name="drname"
              onChange={handleChange}
              value={values.drname}
            >
              <option disabled selected value={""}>
                Pick Doctor
              </option>
              {doctors.map((doctor) => (
                <option>{doctor}</option>
              ))}
            </select>
          </label>
          {touched.drname && errors.drname ? (
            <div className="text-red-600">{errors.drname}</div>
          ) : null}
          {values.drname ? (
            <label className="form-control mb-3 bg-white ">
              {/* <div className="label">
    <span className="label-text">Pick Doctor</span>
  </div> */}

              <select
                className="select select-bordered bg-white "
                name="date"
                onChange={handleChange}
                value={values.date}
              >
                <option disabled selected value={""}>
                  Pick Date
                </option>
                {dates[values.drname].map((date) => (
                  <option>{date}</option>
                ))}
              </select>
            </label>
          ) : null}
          {touched.date && errors.date ? (
            <div className="text-red-600">{errors.date}</div>
          ) : null}
          {values.date ? (
          <label className="form-control bg-white mb-3">
            {/* <div className="label">
    <span className="label-text">Pick Doctor</span>
  </div> */}

            <select
              className="select select-bordered bg-white"
              name="time"
              onChange={handleChange}
              value={values.time}
            >
              <option disabled selected value={""}>
                Pick time
              </option>
              {times[values.drname][values.date].map((time) => (
                <option>{time}</option>
              ))}
            </select>
          </label>
        ) : null}
        {touched.time && errors.time ? (
          <div className="text-red-600">{errors.time}</div>
        ) : null}

          <div className="flex justify-end mt-4">
            <button
              type="submit"
              onClick={handleSubmit}
              // onClick={close()}
              className="btn btn-neutral"
            >
              Send
            </button>
          </div>
        </div>
      </dialog>
    </div>
  );
};

export default Dashboard;
